import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import p1 from 'assests/Photos/ClassAssembly/2023/1B/1.jpg';
import p2 from 'assests/Photos/ClassAssembly/2023/1B/2.jpg';
import p3 from 'assests/Photos/ClassAssembly/2023/1B/3.jpg';
import p4 from 'assests/Photos/ClassAssembly/2023/1B/4.jpg';
// import p5 from 'assests/Photos/ClassAssembly/2023/3B/5.jpg';
// import p6 from 'assests/Photos/ClassAssembly/2023/3B/6.jpg';



import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/ClassAssembly2023';

const ClassAssembly1B2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    }, 
    {
        src: p4,
        source: p4,
        rows: 1,
        cols: 1,
    },
    {
          src: p3,
          source: p3,
          rows: 1,
          cols: 2,
      }, 
    {
          src: p2,
          source:p2,
          rows: 1.2,
          cols: 2,
        },
     
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      Acts of Kindness
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/>
        Class: 1B   Date: 22 and 23 FEBRUARY 2024      
        </Typography>
        {/* <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
         “Gratitude is the inward feeling of kindness received. Thankfulness is the natural impulse to express that feelings. Thanksgiving is the following of that impulse”
        <br/>
        </Typography> */}
        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        Students of Class IB presented the class assembly on the topic “Acts of Kindness” on Thursday, 22 February 2024 and Friday. 23 February 2024. The students presented an endearing thought for the day with great confidence and shared the ways to show kindness towards everyone. They also recited a shloka and explained the meaning of it which brought out the importance of being kind.  The students wore bright costumes and performed an energetic dance as a finale to the assembly signifying the importance of being kind. The students were appreciated for their confidence and enthusiasm. 
        <br></br>
          <div>
          </div>
        </Typography>
        </Box>
        <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
        "Being kind can be magical ….."
        <br/>
        </Typography>
        <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SidebarArticles />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly1B2023;